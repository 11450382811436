const  formatName = {
  filters: {
    pascalCase(name) {
      if (!name) return "Não Informado"

      const lowercaseWords = ['de', 'da', 'do', 'das', 'dos', 'e', 'o', 'a', 'os', 'as', 'em'];

      const words = name.toLowerCase().split(' ');

      const formattedWords = words.map(word => {
        return lowercaseWords.includes(word) ? word : word.charAt(0).toUpperCase() + word.slice(1);
      });

      return formattedWords.join(' ');
    },

    camalCase(name) {
      if (!name) return "Não Informado"

      const words = name.toLowerCase().split(' ');

      const formattedWords = words.map((word, index) => {
        if (index === 0) {
          return word;
        }

        return word.charAt(0).toUpperCase() + word.slice(1);
      });

      return formattedWords.join(' ');
    },

    lowerCase(name) {
      if (!name) return "Não Informado"

      const words = name.toLowerCase();

      return words;
    },

    upperCase(name) {
      if (!name) return "Não Informado"

      const words = name.toUpperCase();

      return words;
    }
  }
};

export default formatName;