<template>
  <v-container grid-list-xs>
    <BaseFilter
      @change:pago="getCampanhaApuracao()"
      @change:ganhou="getCampanhaApuracao()"
      @clear:ganhou="getCampanhaApuracao(true)"
      @clear:pago="getCampanhaApuracao()"
      @change:empresa="getCampanhaApuracao()"
      @change:funcao="getCampanhaApuracao()"
      @change:indicadores="getCampanhaApuracao()"
      ref="filters"
      :options="options"
      class="mb-5"
    >
    </BaseFilter>
    <v-card>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="getApuracao.itemsColaboradorCampanha"
        :sort-by="['funcao', 'apelido']"
        :sort-desc="false"
        class="elevation-1"
        :loading="loading"
        loading-text="Carregando... Aguarde"
        style="white-space: nowrap"
        no-data-text="Sem dados disponíveis"
        :footer-props="{
          'items-per-page-text': 'Colaborador por pagina'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Meta X Resultado por Colaborador</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              class="mt-3"
              v-model="search"
              placeholder="Buscar"
              prepend-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:[`item.funcao`]="{ item }">
          <BaseLineTableCodDescription 
            :cod="item.cod_funcao"
            :description="item.funcao"
          />
        </template>

        <template v-slot:[`item.colaborador`]="{ item }">
          <BaseLineTableCodDescription 
            :cod="item.id_colaborador"
            :description="item.colaborador"
          />
        </template>

        <template v-slot:[`item.indicador`]="{ item }">
          <BaseLineTableCodDescription 
            :cod="item.id_indicador"
            :description="item.indicador"
          />
        </template>

        <template v-slot:[`item.tipo_meta`]="{ item }">
          <IconTipoMeta
            :text="item.tipo_meta"
            :idTipoMeta="item.id_tipo_meta"
          />
        </template>

        <template v-slot:[`item.tipo_calculo`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> {{ item.id_tipo_calculo == '2' ? 'mdi-cart-variant' : 'mdi-chart-line' }} </v-icon>
            </template>
            <span> {{ item.tipo_calculo }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.base_calculo`]="{ item }">
          <div v-if="item.base_calculo == 'V'">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-currency-usd</v-icon>
              </template>
              <span>Valor</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on">QTDE</p>
              </template>
              <span>Quantidade</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.valor_bonus`]="{ item }">
          {{ item.valor_bonus | BrazilianCurrency }}
        </template>

        <template v-slot:[`item.data_inicio`]="{ item }">
          {{ item.data_inicio | BrazilianStandardDate }}
        </template>
        <template v-slot:[`item.data_final`]="{ item }">
          {{ item.data_final | BrazilianStandardDate }}
        </template>

        <template v-slot:[`item.valor_meta`]="{ item }">
          <p v-if="item.base_calculo == 'V'">
            {{ item.valor_meta | BrazilianCurrency }}
          </p>
          <p v-else>
            {{ item.valor_meta }}
          </p>
        </template>

        <template v-slot:[`item.valor_realizado`]="{ item }">
          <p v-if="item.base_calculo == 'V'">
            {{ item.valor_realizado | BrazilianCurrency }}
          </p>
          <p v-else>
            {{ item.valor_realizado }}
          </p>
        </template>

        <template v-slot:[`item.dif_v_meta_realizado`]="{ item }">
          <v-icon :color="item.dif_v_meta_realizado == '0' ? 'warning' : 'success'">
            {{ item.dif_v_meta_realizado == '0' ? 'mdi-arrow-down-thick' : 'mdi-arrow-up-thick' }}
          </v-icon>
        </template>

        <template v-slot:[`item.perc_crescimento`]="{ item }">
          {{ item.perc_crescimento }}%
        </template>
        <template v-slot:[`item.cresc_geral`]="{ item }">
          {{ item.cresc_geral }}%
        </template>
        <template v-slot:[`item.superou_cresc`]="{ item }">
          <v-icon :color="convertValue(item.cresc_geral) > convertValue(item.perc_crescimento) ? 'success' : 'warning'">
            {{ convertValue(item.cresc_geral) > convertValue(item.perc_crescimento) ? 'mdi-arrow-up-thick' : 'mdi-arrow-down-thick' }}
          </v-icon>
        </template>

        <template v-slot:[`item.valor_result`]="{ item }">
          {{ item.valor_result | BrazilianCurrency }}
        </template>
        <template v-slot:[`item.valor_result_estimado`]="{ item }">
          {{
            (item.valor_result_estimado != "0"
              ? item.valor_result_estimado
              : calculaBonus(
                  item.id_tipo_calculo,
                  item.valor_meta,
                  item.valor_realizado,
                  item.valor_bonus
                )) | BrazilianCurrency
          }}
        </template>

        <template v-slot:[`item.ganhou`]="{ item }">
          <IconGanhou :ganhou="item.ganhou" />
        </template>
        <template v-slot:[`item.superou_gmr`]="{ item }">
          <SuperouGmr :superou="item.superou_gmr" />
        </template>

        <template v-slot:[`item.pago`]="{ item }">
          <IconPago :pago="item.pago" />
        </template>

      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import dateMixin from "../../../mixins/dateMixin";
import currencyMixin from "../../../mixins/currencyMixin";
import BaseLineTableCodDescription from "@/components/shared/BaseLineTableCodDescription";
import { mapGetters, mapActions, mapState } from "vuex";
import { barramento } from "./barramento";


export default {
  name: "section-list-colaborador-campanhaFuncao",
  components: {
    BaseFilter: () => import("@/components/shared/BaseFilter"),
    IconGanhou: () => import("@/components/shared/icons/Ganhou"),
    IconPago: () => import("@/components/shared/icons/Pago"),
    SuperouGmr: () => import("@/components/shared/icons/SuperouGmr"),
    IconTipoMeta: () => import("@/components/shared/icons/TipoMeta"),
    BaseLineTableCodDescription
  },
  mixins: [dateMixin, currencyMixin],

  data() {
    return {
      options: [
        {
          label: "Empresa",
          service: "empresa",
          itemText: "apelido",
          itemList: "apelido",
          itemValue: "id_empresa",
          filter: {
            per_page: -1,
            ativa_campanha: "S"
          },
          col: {
            lg: 2,
            sm: 12,
            md: 2
          }
        },
        {
          label: "Função",
          service: "funcao",
          itemText: "funcao",
          itemList: "funcao",
          itemValue: "id_funcao",
          showCode: true,
          cod: "cod_funcao",
          filter: {
            per_page: -1
          },
          col: {
            lg: 3,
            sm: 12,
            md: 3
          }
        },
        {
          label: "Indicador",
          service: "indicadores",
          itemText: "indicador",
          itemList: "indicador",
          itemValue: "id_indicador",
          showCode: true,
          cod: "id_indicador",
          filter: {
            per_page: -1
          },
          col: {
            lg: 3,
            sm: 12,
            md: 3
          }
        },
        {
          label: "Ganhou",
          service: "ganhou",
          items: [
            {
              value: "S",
              text: "Sim"
            },
            {
              value: "N",
              text: "Não"
            }
          ],
          col: {
            lg: 2,
            sm: 12,
            md: 2
          }
        },
        {
          label: "Pago",
          service: "pago",
          items: [
            {
              value: "S",
              text: "Sim"
            },
            {
              value: "N",
              text: "Não"
            }
          ],
          col: {
            lg: 2,
            sm: 12,
            md: 2
          }
        }
      ],
      search: "",
      loading: false,
      headers: [
        {
          text: "Empresa",
          align: "start",
          value: "apelido"
        },
        { text: "Função", align: "left", value: "funcao" },
        { text: "Colaborador", align: "left", value: "colaborador" },
        { text: "Indicador", align: "left", value: "indicador" },
        { text: "Tipo Meta", align: "center", value: "tipo_meta" },
        { text: "Tipo Cálculo", align: "center", value: "tipo_calculo" },
        { text: "Base Cálculo", align: "center", value: "base_calculo" },
        { text: "Data Inicial", align: "center", value: "data_inicio" },
        { text: "Data Final", align: "center", value: "data_final" },
        { text: "Meta GMR", value: "valor_meta_gmr", align: "right" },
        { text: "Realizado GMR", value: "valor_realizado_gmr", align: "right" },
        { text: "Superou GMR", value: "superou_gmr", align: "center" },

        { text: "Meta", value: "valor_meta", align: "right" },
        { text: "Realizado", value: "valor_realizado", align: "right" },
        { text: "Superou Meta", value: "dif_v_meta_realizado", align: "right" },

        { text: "Cresc. Estimado", value: "perc_crescimento", align: "right" },
        { text: "Cresc. Realizado", value: "cresc_geral", align: "right" },
        { text: "Superou Cresc.", value: "superou_cresc", align: "right" },

        { text: "Bônus", value: "valor_bonus", align: "right" },
        { text: "Resultado", value: "valor_result", align: "right" },
        { text: "Bônus Estimado", value: "valor_result_estimado", align: "right" },
        { text: "Ganhou", value: "ganhou", align: "center" },
        { text: "Pago", value: "pago", align: "center" }
      ]
    };
  },

  computed: {
    ...mapState("campanha", {
      apuracaoFilters: state => state.apuracao.filters
    }),
    ...mapGetters({
      getApuracao: "campanha/getApuracao"
    })
  },

  methods: {
    ...mapActions({
      actionApuracaoColaboradorCampanha:
        "campanha/actionApuracaoColaboradorCampanha"
    }),
    async getCampanhaApuracao(clear) {
      if (clear) this.$refs.filters.selected.ganhou = "";
      try {
        this.loading = true;
        const filter = {
          ganhou: this.$refs.filters?.selected.ganhou,
          pago: this.$refs.filters?.selected.pago,
          id_empresa: this.$refs.filters?.selected.empresa,
          id_funcao: this.$refs.filters?.selected.funcao,
          id_indicador: this.$refs.filters?.selected.indicadores,
          id_campanha_funcao: this.getApuracao.filters.id_campanha_funcao,
          id_campanha: this.$route.params.idCampanha
        };

        await this.actionApuracaoColaboradorCampanha(filter);
        //teste de commit
      } catch (error) {
        this.$notify({
          text: "Erro ao listar",
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    },

    calculaBonus(tipoCalculo, meta, realizado, bonus) {
      if (tipoCalculo != 2) {
        return this.calculaBonusMetaAlcancada(meta, realizado, bonus);
      }
      return this.calculaBonusMetaVendido(realizado, bonus);
    },

    calculaBonusMetaAlcancada(meta, realizado, bonus) {
      return realizado > meta ? bonus : "0";
    },

    calculaBonusMetaVendido(realizado, bonus) {
      return parseInt(realizado) * parseInt(bonus);
    },

    convertValue(value) {
      return parseFloat(value)
    }
  },

  mounted() {
    this.getCampanhaApuracao();
    barramento.$on("click:rowTablePublico", this.getCampanhaApuracao);
  }
};
</script>
