<template>
<div class="my-2">
	<p class="pa-0 ma-0 text-body-2 font-weight-black">
		{{ cod }}
	</p>
	<p class="pa-0 ma-0">{{ description | pascalCase }}</p>
</div>
</template>

<script>
import formatName from "@/mixins/formatName";
export default {
  name: "BaseLineTableCodDescription",

  mixins: [formatName],

  props: {
    cod: {
      type: String
    },
    description:{
      type: String
    }
  }
}
</script>

<style>

</style>