var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xs":""}},[_c('BaseFilter',{ref:"filters",staticClass:"mb-5",attrs:{"options":_vm.options},on:{"change:pago":function($event){return _vm.getCampanhaApuracao()},"change:ganhou":function($event){return _vm.getCampanhaApuracao()},"clear:ganhou":function($event){return _vm.getCampanhaApuracao(true)},"clear:pago":function($event){return _vm.getCampanhaApuracao()},"change:empresa":function($event){return _vm.getCampanhaApuracao()},"change:funcao":function($event){return _vm.getCampanhaApuracao()},"change:indicadores":function($event){return _vm.getCampanhaApuracao()}}}),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"white-space":"nowrap"},attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.getApuracao.itemsColaboradorCampanha,"sort-by":['funcao', 'apelido'],"sort-desc":false,"loading":_vm.loading,"loading-text":"Carregando... Aguarde","no-data-text":"Sem dados disponíveis","footer-props":{
        'items-per-page-text': 'Colaborador por pagina'
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Meta X Resultado por Colaborador")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{staticClass:"mt-3",attrs:{"placeholder":"Buscar","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.funcao",fn:function(ref){
      var item = ref.item;
return [_c('BaseLineTableCodDescription',{attrs:{"cod":item.cod_funcao,"description":item.funcao}})]}},{key:"item.colaborador",fn:function(ref){
      var item = ref.item;
return [_c('BaseLineTableCodDescription',{attrs:{"cod":item.id_colaborador,"description":item.colaborador}})]}},{key:"item.indicador",fn:function(ref){
      var item = ref.item;
return [_c('BaseLineTableCodDescription',{attrs:{"cod":item.id_indicador,"description":item.indicador}})]}},{key:"item.tipo_meta",fn:function(ref){
      var item = ref.item;
return [_c('IconTipoMeta',{attrs:{"text":item.tipo_meta,"idTipoMeta":item.id_tipo_meta}})]}},{key:"item.tipo_calculo",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.id_tipo_calculo == '2' ? 'mdi-cart-variant' : 'mdi-chart-line')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.tipo_calculo))])])]}},{key:"item.base_calculo",fn:function(ref){
      var item = ref.item;
return [(item.base_calculo == 'V')?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-currency-usd")])]}}],null,true)},[_c('span',[_vm._v("Valor")])])],1):_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({},'p',attrs,false),on),[_vm._v("QTDE")])]}}],null,true)},[_c('span',[_vm._v("Quantidade")])])],1)]}},{key:"item.valor_bonus",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_bonus))+" ")]}},{key:"item.data_inicio",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_inicio))+" ")]}},{key:"item.data_final",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_final))+" ")]}},{key:"item.valor_meta",fn:function(ref){
      var item = ref.item;
return [(item.base_calculo == 'V')?_c('p',[_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_meta))+" ")]):_c('p',[_vm._v(" "+_vm._s(item.valor_meta)+" ")])]}},{key:"item.valor_realizado",fn:function(ref){
      var item = ref.item;
return [(item.base_calculo == 'V')?_c('p',[_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_realizado))+" ")]):_c('p',[_vm._v(" "+_vm._s(item.valor_realizado)+" ")])]}},{key:"item.dif_v_meta_realizado",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.dif_v_meta_realizado == '0' ? 'warning' : 'success'}},[_vm._v(" "+_vm._s(item.dif_v_meta_realizado == '0' ? 'mdi-arrow-down-thick' : 'mdi-arrow-up-thick')+" ")])]}},{key:"item.perc_crescimento",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.perc_crescimento)+"% ")]}},{key:"item.cresc_geral",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.cresc_geral)+"% ")]}},{key:"item.superou_cresc",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.convertValue(item.cresc_geral) > _vm.convertValue(item.perc_crescimento) ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(_vm.convertValue(item.cresc_geral) > _vm.convertValue(item.perc_crescimento) ? 'mdi-arrow-up-thick' : 'mdi-arrow-down-thick')+" ")])]}},{key:"item.valor_result",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_result))+" ")]}},{key:"item.valor_result_estimado",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")((item.valor_result_estimado != "0" ? item.valor_result_estimado : _vm.calculaBonus( item.id_tipo_calculo, item.valor_meta, item.valor_realizado, item.valor_bonus ))))+" ")]}},{key:"item.ganhou",fn:function(ref){
      var item = ref.item;
return [_c('IconGanhou',{attrs:{"ganhou":item.ganhou}})]}},{key:"item.superou_gmr",fn:function(ref){
      var item = ref.item;
return [_c('SuperouGmr',{attrs:{"superou":item.superou_gmr}})]}},{key:"item.pago",fn:function(ref){
      var item = ref.item;
return [_c('IconPago',{attrs:{"pago":item.pago}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }